.first-view-section {
  background-image: url("../../../../../public/icons/backgrounds/first-view-bg.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  .icon-container {
    background-image: url("../../../../../public/icons/backgrounds/first-view-panda-bg.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .title {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    max-width: 1126px;
    @media (min-width: 600px) {
      font-size: 24px;
      line-height: 38px;
    }
    @media (min-width: 800px) {
      font-size: 30px;
      line-height: 48px;
    }
  }
  .description {
    max-width: 720px;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
    @media (min-width: 600px) {
      font-size: 20px;
      line-height: 32px;
    }
    @media (min-width: 800px) {
      font-size: 24px;
      line-height: 40px;
      margin-top: 32px;
    }
  }
}