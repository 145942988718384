.perk-item {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
  min-height: 164px;
  @media (min-width: 400px) {
    min-width: 360px;
  }
  .perk-item-title {
    font-family: DaysOne, serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    max-width: 240px;
    @media (min-width: 540px) {
      max-width: 278px;
      font-size: 24px;
      line-height: 36px;
    }
  }
  .perk-item-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    max-width: 240px;
    @media (min-width: 540px) {
      max-width: 358px;
      font-size: 18px;
    }
    @media (min-width: 1280px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .icon-container {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    box-sizing: border-box;
    margin-inline-start: 0;
    margin-inline-end: 16px;
    @media (min-width: 768px) {
      margin-inline-start: 16px;
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
    }
    @media (min-width: 1024px) {
      margin-inline-end: 0;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.perk-item-reverse {
  .icon-container {
    margin-inline-start: 0;
    margin-inline-end: 16px;
    @media (min-width: 768px) {
      margin-inline-start: 16px;
    }
    @media (min-width: 1024px) {
      margin-inline-start: 0;
    }
  }
}
.center-panda-icon {
  svg {
    width: 240px !important;
    height: 240px !important;
    @media (min-width: 768px) {
      width: 300px;
      height: 280px;
    }
  }
}