.meat-the-team {
  background-color: #282828;
  width: 100%;
  padding: 48px 0;
  @media (min-width: 768px) {
    padding: 48px 48px 8px 48px;
  }
  .title {
    font-family: DaysOne, serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
  .team-container {
    display: flex;
    flex-wrap: wrap;
    .card {
      padding: 32px 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 540px) {
        padding: 32px;
        width: 50%;
      }
      @media (min-width: 1024px) {
        width: 25%;
      }
      .card-title {
        font-family: DaysOne, serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.3px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 16px;
      }
      .card-member {
        color: #FFFFFF;
        font-family: Mohave, serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}