.perks-section {
  .title {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    max-width: 1126px;
    @media (min-width: 600px) {
      font-size: 24px;
      line-height: 38px;
    }
    @media (min-width: 800px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  .desc {
    max-width: 878px;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    @media (min-width: 600px) {
      font-size: 20px;
      line-height: 32px;
    }
    @media (min-width: 800px) {
      font-size: 24px;
      line-height: 40px;
    }
  }
}