.faq-section {
  width: calc(100% - 32px);
  max-width: 977px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-right: 16px;
  margin-left: 16px;
  @media (min-width: 768px) {
    margin-top: 64px;
  }
  .title {
    font-family: DaysOne, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 24px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 18px;
      margin-bottom: 48px;
    }
  }
  .faq-item {
    background-color: #FFFFFF;
    margin: 16px 0;
    border-radius: 20px;
    overflow: hidden;
    .faq-question {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100px;
      transition-duration: 0.2s;
      p {
        font-family: DaysOne, serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.3px;
        text-align: left;
        @media (min-width: 768px) {
          font-size: 22px;
          line-height: 18px;
        }
      }
    }
    .faq-question-open {
      background-color: #282828;
      color: #FFFFFF;
    }
    .faq-answer {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s ease-out;
      p {
        font-family: Mohave, serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 1px;
        text-align: left;
        padding: 16px;
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}