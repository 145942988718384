.pre-launch-section {
  background-color: #DE8600;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 16px;
  flex-direction: column-reverse;
  padding: 0 16px 16px 16px;
  @media (min-width: 1100px) {
    flex-direction: row;
    padding: 0;
  }
  .info {
    background-color: white;
    border-radius: 16px;
    border: 4px solid #282828;
    width: 100%;
    padding: 24px 16px 16px 16px;
    margin: 0 48px;
    @media (min-width: 540px) {
      padding: 64px 48px;
    }
    @media (min-width: 1100px) {
      max-width: 600px;
    }
    .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 18px;
    }
    .desc {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      margin: 32px 0;
    }
    .join-btn {
      width: 100%;
      max-width: 310px;
      height: 68px;
      font-size: 28px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1px;
    }
  }
}