.footer-section {
  background-color: #282828;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 8px 8px 8px;
  @media (min-width: 768px) {
    padding: 48px 48px 8px 48px;
  }
  .white-dot {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .orange-dot {
      width: 20px;
      height: 20px;
      background-color: #FF8C0E;
      border-radius: 50%;
    }
  }
  .name {
    font-family: DaysOne, serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #FFFFFF;
  }
  .footer-social {
    font-family: Mohave, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #FFFFFF;
  }
  .copy-right {
    margin-top: 32px;
    font-family: Mohave, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 66px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;
  }
}