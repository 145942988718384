@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Mohave;
  src: url('../fonts/Mohave.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: DaysOne;
  src: url('../fonts/DaysOne-Regular.ttf') format('truetype');
  font-weight: 400;
}

.mohave-font {
  font-family: Mohave, serif;
  letter-spacing: 1px;
}

.notable-font {
  font-family: DaysOne, serif;
}

.app {
  //padding-top: 90px;
}

html {
  scroll-behavior: smooth;
}