.core-features-section {
  background-color: #282828;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  .content {
    width: 100%;
    max-width: 1180px;
    .title {
      font-family: DaysOne, serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 1px;
      text-align: left;
      color: #FFFFFF;
      max-width: 840px;
      @media (min-width: 540px) {
        font-size: 24px;
        line-height: 36px;
      }
      @media (min-width: 768px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    .grid-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 32px;
      @media (min-width: 1024px) {
        align-content: center;
        justify-content: space-between;
        flex-direction: row;
      }
    }
    .white-box {
      background-color: #FFFFFF;
      border-radius: 20px;
      padding: 12px 20px;
      @media (min-width: 768px) {
        padding: 24px 40px;
      }
    }
    .box-1 {
      width: 100%;
      @media (min-width: 1024px) {
        width: 60%;
      }
      @media (min-width: 1280px) {
        width: 65%;
      }
      img {
        width: 64px;
        height: 64px;
        @media (min-width: 400px) {
          width: 84px;
          height: 84px;
        }
        @media (min-width: 540px) {
          width: 112px;
          height: 112px;
        }
        @media (min-width: 768px) {
          width: 150px;
          height: 150px;
        }
        @media (min-width: 1280px) {
          width: 189px;
          height: 189px;
        }
      }
      .sub-title {
        font-family: Mohave, serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: left;
        @media (min-width: 540px) {
          font-size: 24px;
        }
      }
      .sub-description {
        font-family: Mohave, serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: left;
        @media (min-width: 540px) {
          font-size: 20px;
        }
      }
    }
    .box-2 {
      width: fit-content;
      margin: 0 auto;
      @media (min-width: 1024px) {
        width: 40%;
      }
      @media (min-width: 1280px) {
        width: 35%;
      }
      height: fit-content;
      .list-item-text {
        font-family: Mohave, serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: left;
        margin: 8px 0;
        @media (min-width: 540px) {
          font-size: 22px;
        }
      }
    }
  }
}