.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  @media (min-width: 900px) {
    padding: 0 100px;
  }
  .logo {
    font-size: 24px;
    line-height: 32px;
  }
}