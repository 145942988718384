.g-button {
  outline: none;
  border: 0;
  min-height: 42px;
  border-radius: 16px;
  color: white;
  background-color: #282828;
  min-width: 74px;
  font-family: Mohave, serif;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.94;
  }
}